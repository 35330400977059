import React from 'react'
import Layout from '../../components/layout'
import { ParagraphsCreator } from '../../components/ChallengeCreators'

const ParagraphsCreatorPage = ({ challengeId }) => (
  <Layout>
    <ParagraphsCreator challengeId={challengeId} />
  </Layout>
)

export default ParagraphsCreatorPage
